import * as React from "react"
import { useRef, useEffect } from "react"
import gsap, { TimelineLite, TweenMax, Power3 } from "gsap"
import CSSRulePlugin from "gsap/CSSRulePlugin"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AppStore from "../images/appstore.svg"
import PlayStore from "../images/playstore.svg"
import Footer from "../components/footer"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const IndexPage = () => {
  gsap.registerPlugin(CSSRulePlugin)
  gsap.registerPlugin(ScrollTrigger)

  let tl = new TimelineLite({ delay: 0.8 })

  let app = useRef(null)
  let content = useRef(null)

  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: "visible" } })

    tl.from([".word-one", ".word-two", ".word-three"], {
      y: 200,
      ease: "power4.out",
      opacity: 0,
      skewY: 10,
      duration: 0.4,
      stagger: {
        amount: 0.6,
      },
    })
      .from(".hero-btn", {
        opacity: 0,
        x: 100,
        stagger: {
          amount: 0.4,
        },
      })
      .from(".hero-image", {
        y: 200,
        ease: "power4.out",
        opacity: 0,
        duration: 0.4,

        stagger: {
          amount: 0.4,
        },
      })
      .from(".app-box", {
        y: 50,
        ease: Power3.easeInOut,
        opacity: 0,
        duration: 0.6,

        stagger: {
          amount: 0.4,
        },
        // scrollTrigger: {
        //   trigger: ".app-box"
        // }
      })
      .from(".app-image", {
        y: 50,
        ease: Power3.easeInOut,
        opacity: 0,
        duration: 0.8,

        stagger: {
          amount: 0.4,
        },
      })
  }, []);

  const downloadApp  = (url) => {
    if(typeof window !== "undefined"){
      window.open(url, '_blank', "noreferrer, noopener");
    }
  }

  return (
    <div className="home-container" ref={el => (app = el)}>
      <Layout>
        <Seo title="Home" />
        <section>
          <div className="max-w-full xl:min-h-screen flex px-6 flex-col md:flex-col xl:flex-row">
            <div
              className=" flex flex-col xl:w-3/5 bg-grey p-6 pr-10 justify-center"
              ref={el => (content = el)}
            >
              <div className="flex flex-col w-10/12">
                <span className="word-one antialiased font-bold leading-normal text-2xl md:text-5xl py-3">
                  Stay Connected with   We Rise
                  {/* “Choosing how you vote should not be a snap verdict based on a few minutes of television” */}

                </span>
              </div>
              {/* <div className="xl:py-6 w-full py-3 flex justify-start" >
                <span className="word-two antialiased text-left font-normal text-2xl xl:text-4xl">

                  -Simon Cowell
                </span>
              </div> */}
              <div className="my-4 md:w-10/12 text-justify">
                <span className="word-three antialiased text-xs text-justify md:text-base font-light">
                  {/* Get the latest information on campaigns, political leaders and aspirants in your District and County */}
                  We Rise is a voter empowerment App designed primarily to create an active democratic citizenry in the United States of America. It is designed to provide a single point of service and information delivery to voters about polling station information, candidate information, locations of where to vote, ballot initiatives, and election history on state-wide and legislative elections. The We Rise application can be freely downloaded from the app stores of Google (for Android devices) and Apple (for iOS devices) by clicking the button below.
                   All elections that affect our daily lives, and not the big presidential or governorship, but those local elections.
                </span>
              </div>
              <div className="my-10 md:my-10">
                <button onClick={() => { downloadApp('https://apps.apple.com/ng/app/werise-empowerment/id1610295267'); downloadApp('https://play.google.com/store/apps/details?id=com.werise') }} className="hero-btn btn bg-black px-6 py-3  rounded-md text-white btn--primary">
                  Download App
                </button>
              </div>
            </div>

            <div className="flex justify-center items-center xl:w-2/5 hero-pattern bg-hero-pattern bg-cover">
              <StaticImage
                className="hero-image -mt-20 md:-ml-40"
                src="../images/app.png"
                width={600}
                height={600}
                quality={95}
                formats={["auto", "png"]}
                alt="Werise App"
              />
            </div>
          </div>
        </section>
        <div id="about">
          <section className=" flex flex-col justify-center items-center max-w-full py-20">
            <div className="py-4">
              <h1 className="antialiased text-center font-bold text-3xl md:text-5xl">
                {" "}
                How it works
              </h1>
            </div>
            <div className="py-3 max-w-sm text-center">
              <p className="font-light">
                Follow these 3 simple steps and start using the WeRise app for
                free.
              </p>
            </div>
            <div className="w-11/12 cursor-pointer mx-auto grid grid-cols-1 xl:min-h-screen md:grid-cols-3 md:items-center  xl:grid-cols-3 py-14 md:py-14 gap-4 justify-items-center border-light border-b-2">
              <div className=" app-box flex flex-col items-center py-10 md:py-0">
                <div className="animate-dropin">

                  <StaticImage
                    // src="https://res.cloudinary.com/dksyt7yvf/video/upload/v1639047566/Sequence_01_1_zkwqfv.mp4"
                    src="../images/first.png"
                    width={200}
                    height={200}
                    quality={95}
                    formats={["auto", "mp4"]}
                    alt="How it works Step One"
                  />
                </div>
                <div className="flex flex-col max-h-48 py-8 max-w-sm text-center">
                  <h3 className="text-xl font-bold text-center py-8">
                    Download App
                  </h3>
                  <p className="text-md font-light text-center max-w-xs">
                    Download the WeRise mobile app
                    for IOS and andriod. Never miss an update.
                    Stay connected at all time.
                  </p>
                </div>
              </div>
              <div className="app-box flex flex-col items-center py-10 md:py-0">
                <div>
                  <StaticImage
                    src="../images/second.png"
                    width={200}
                    height={200}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="How it works Step Two"
                  />
                </div>
                <div className="flex flex-col max-h-48 py-8 max-w-sm text-center">
                  <h3 className="text-xl font-bold py-8">Add Location</h3>
                  <p className="text-md font-light text-center max-w-xs">
                    Set your location on the app to enable you view leads and
                    candidates in your area.
                  </p>
                </div>
              </div>
              <div className="app-box flex flex-col items-center py-10 md:py-0">
                <div>
                  <StaticImage
                    src="../images/third.png"
                    width={200}
                    height={200}
                    quality={95}
                    formats={["auto", "png"]}
                    alt="How it works Step Three"
                  />
                </div>

                <div className="app-image flex flex-col max-h-48 py-8 max-w-sm text-center">
                  <h3 className="text-xl font-bold py-8">Access</h3>
                  <p className="text-md font-light text-center max-w-xs">
                    Enjoy non-stop updates on your mobile app. Get latest press
                    news, interviews and a lot more.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div data-scroll-section id="features">
          <section className=" w-11/12 mx-auto min-h-screen py-20 flex flex-col xl:flex-row items-center justify-between">
            <div className="flex xl:w-2/5 items-center justify-center">
              <StaticImage
                className="app-image"
                src="../images/screen.png"
                width={350}
                quality={95}
                formats={["auto", "png"]}
                alt="Werise App Screen"
              />
                          {/* <img

src="https://res.cloudinary.com/dksyt7yvf/image/upload/v1639152464/Sequence_01_1_rx7ltc.gif"

style={{
  width: '380px',
  height: '700px',

}}

alt="Werise App Screen"
/> */}

            </div>

            <div className=" flex justify-center flex-col items-center xl:items-start text-left xl:w-2/5">
              <h1 className="antialiased text-center xl:text-left font-bold text-3xl md:text-5xl py-10">
                Download
              </h1>
              <p className="text-md font-light text-center xl:text-left max-w-xs py-10">
                Download the WeRise mobile app for IOS and andriod. Never miss
                an update. Stay connected at all time.
              </p>

              <div className="flex flex-col justify-between">
                <div onClick={() => downloadApp('https://apps.apple.com/ng/app/werise-empowerment/id1610295267')} className="rounded-2xl border-2 flex my-5 items-center justify-center border-light px-10 py-5 cursor-pointer">
                  <AppStore />
                  <div className="flex ml-5 gap-1 flex-col">
                  <span className="">App Store</span>
                  {/* <span className="text-xs">Coming Soon</span> */}
                  </div>

                </div>
                <div onClick={() => downloadApp('https://play.google.com/store/apps/details?id=com.werise')} className="rounded-2xl border-2 flex items-center justify-center border-light px-5 py-5 cursor-pointer">
                  <PlayStore />
                  <span className="ml-5">Play Store</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section className=" w-11/12 xl:w-5/6 mx-auto min-h-screen py-20 flex flex-col xl:flex-row items-center justify-between border-light border-b-2">
            <div className="app-image xl:w-2/5 flex justify-center flex-col md:items-center xl:items-start">
              <h1 className="antialiased text-center xl:text-left font-bold text-3xl md:text-5xl py-10">
                Stay Connected
              </h1>
              <p className="text-md font-light text-center xl:text-left max-w-xs py-10">
                Stay updated with news about people in power in your counties
                and districts..
              </p>
              <div className="flex flex-col justify-center items-center">
                <div className="rounded-2xl border-2 flex my-5 items-center justify-center w-60 border-light px-10 py-5 cursor-pointer">
                  Get Started
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <StaticImage
                className="app-image"
                src="../images/group.png"
                width={600}
                quality={95}
                formats={["auto", "png"]}
                alt="Werise App Screen"
              />
            </div>
          </section>
        </div>
        <div id="contact">
          <section className="flex flex-col p-5 py-28 justify-center items-center">
            <h1 className="antialiased text-center xl:text-left font-bold text-3xl md:text-5xl py-10">
              Subscribe To Our Newsletter
            </h1>

            <div className="flex items-center flex-col xl:flex-row justify-between w-full xl:w-2/4 xl:mx-auto">
              <input
                type="text"
                placeholder="Enter your email "
                className="focus:border-light bg-lightgrey focus:ring-1 focus:ring-light focus:outline-none  w-full xl:w-2/3 text-black placeholder-gray-500 border text-md border-gray-200 rounded-md py-5 px-3"
              />
              <button className="text-white bg-black rounded-md py-5 xl:ml-10 w-full xl:my-0 my-10 xl:w-1/2">
                Subscribe
              </button>
            </div>
          </section>
          <Footer />
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage
